.blog-standard-content {
	.news-block-one {
		.lower-content {
			.category {
				a {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.inner-box {
			border-bottom: 1px solid #e5e5e5;
		}
	}
}
.news-block-one.video-block {
	.image-box {
		.video-btn {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			a {
				background: #fff;
				&:before {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					background: transparent;
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					-webkit-animation-delay: .9s;
					animation-delay: .9s;
					content: "";
					position: absolute;
					-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
					box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
					-webkit-animation: ripple 3s infinite;
					animation: ripple 3s infinite;
					-webkit-transition: all .4s ease;
					transition: all .4s ease;
				}
				&:after {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					background: transparent;
					position: absolute;
					top: 50%;
					left: 50%;
					-webkit-transform: translate(-50%, -50%);
					transform: translate(-50%, -50%);
					-webkit-animation-delay: .9s;
					animation-delay: .9s;
					content: "";
					position: absolute;
					-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
					box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.9);
					-webkit-animation: ripple 3s infinite;
					animation: ripple 3s infinite;
					-webkit-transition: all .4s ease;
					transition: all .4s ease;
					-webkit-animation-delay: .6s;
					animation-delay: .6s;
				}
			}
		}
		img {
			opacity: 1 !important;
			border-radius: 5px;
		}
		.image {
			&:before {
				position: absolute;
				content: '';
				background: #000;
				width: 100%;
				height: 100%;
				left: 0px;
				top: 0px;
				opacity: 0.3;
				z-index: 1;
				border-radius: 5px;
			}
		}
	}
}
.news-block-one.carousel-block {
	.inner-box {
		.image {
			img {
				opacity: 1 !important;
				border-radius: 5px;
			}
		}
	}
	.owl-nav {
		position: absolute;
		left: 0px;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		button {
			position: absolute;
			display: inline-block;
			width: 50px;
			height: 50px;
			line-height: 50px;
			background: #fff;
			font-size: 20px;
			color: #141417;
			text-align: center;
			border-radius: 50%;
			transition: all 500ms ease;
			&:hover {
				color: #fff;
			}
		}
		button.owl-prev {
			left: 30px;
		}
		button.owl-next {
			right: 30px;
		}
	}
}
.news-block-one {
	.inner-box {
		blockquote {
			.icon-box {
				color: #fceee7;
			}
			h5.name {
				&:before {
					position: absolute;
					content: '';
					background: #141417;
					width: 15px;
					height: 2px;
					left: 0px;
					top: 12px;
				}
			}
		}
	}
}
.news-block-one.quote-block {
	.inner-box {
		overflow: visible;
	}
}
.sidebar-page-container {
	.pagination {
		li {
			border-radius: 5px;
			a {
				border-radius: 5px;
			}
		}
	}
	.inner-container {
		background: #fff;
		&:before {
			position: absolute;
			content: '';
			background: #fff;
			width: calc(100% + 140px);
			height: 100%;
			left: -70px;
			top: 0px;
		}
	}
	.theme-btn {
		border: 1px solid #e7e7e7;
		color: #141417 !important;
		padding: 11px 32px;
		&:hover {
			color: #fff !important;
			border-color: #e45712;
		}
	}
}
.blog-sidebar {
	.sidebar-widget {
		background: #f7f7f7;
	}
	.search-widget {
		.search-form {
			.form-group {
				input[type='search'] {
					position: relative;
					display: inline-block;
					width: 100%;
					height: 60px;
					border: 1px solid #e5e5e5;
					border-radius: 5px;
					font-size: 16px;
					color: #ffffff;
					font-family: 'Poppins', sans-serif;
					padding: 10px 20px 10px 60px;
					transition: all 500ms ease;
					background: #ffffff;
				}
				button {
					position: absolute;
					left: 28px;
					top: 18px;
					font-size: 18px;
					color: #808080;
					cursor: pointer;
					transition: all 500ms ease;
				}
			}
		}
	}
	.post-widget {
		.post {
			border-bottom: 1px solid #e5e5e5;
			min-height: 104px;
			&:last-child {
				border-bottom: none;
			}
			.post-thumb {
				img {
					width: 100%;
					border-radius: 5px;
				}
			}
			&:hover {
				.post-thumb {
					img {
						opacity: 0.2;
					}
				}
			}
			h5 {
				line-height: 24px;
			}
		}
	}
	.tags-widget {
		.tags-list {
			margin: 0px -5px;
			li {
				a {
					padding: 5px 14px;
					border: 1px solid #e5e5e5;
					&:hover {
						color: #fff;
					}
				}
			}
		}
	}
}
.blog-standard-2 {
	.blog-standard-content {
		.news-block-one {
			.inner-box {
				border-bottom: none;
			}
		}
	}
	.news-block-one {
		.inner-box {
			blockquote {
				border-left: none;
				background: #302f2f;
				.icon-box {
					color: #454444;
					left: 50%;
					transform: translateX(-50%);
				}
				h5 {
					color: #fff;
					&:before {
						display: none;
					}
				}
			}
		}
	}
	.blog-sidebar {
		.sidebar-widget {
			background: transparent;
		}
	}
}
.blog-grid-one {
	.news-block-one {
		.inner-box {
			.post-date-two {
				background: #fff;
				h4 {
					color: #141417;
				}
			}
			.theme-btn {
				font-family: 'Poppins', sans-serif;
				font-weight: 500;
				border-radius: 0px;
				padding: 11px 32px;
			}
		}
	}
}
.blog-grid-two {
	.news-block-one {
		.inner-box {
			.theme-btn {
				font-size: 16px;
				border-radius: 30px;
				padding-top: 11px;
				padding-bottom: 11px;
				&:hover {
					border-color: #e45712;
					background: #e45712;
				}
			}
		}
	}
	.pagination {
		li {
			a {
				border-radius: 50%;
			}
		}
	}
}
.blog-masonry-two {
	.more-btn {
		.theme-btn {
			background: #e45712;
			padding: 12px 40px;
		}
	}
	.news-block-one {
		.inner-box {
			blockquote {
				border-left: none;
				background: #302f2f;
				.icon-box {
					color: #454444;
					left: 50%;
					transform: translateX(-50%);
				}
				h5 {
					color: #fff;
					&:before {
						display: none;
					}
				}
			}
		}
	}
	.news-block-one.carousel-block {
		.inner-box {
			.image {
				img {
					border-radius: 0px;
				}
			}
		}
	}
}
.blog-list-content {
	.news-block-one {
		.inner-box {
			padding-left: 370px;
			.image-box {
				width: 370px;
				height: 370px;
			}
			.post-date-two {
				background: #fff;
				h4 {
					color: #141417;
				}
			}
			.theme-btn {
				border-radius: 0px;
			}
		}
	}
}
.blog-details-content {
	.blog-post {
		.content-one {
			.post-title {
				.post-info {
					li {
						&:before {
							position: absolute;
							content: '';
							background: #e5e5e5;
							width: 6px;
							height: 6px;
							top: 12px;
							right: -19px;
							border-radius: 50%;
						}
						&:last-child {
							&:before {
								display: none;
							}
						}
					}
				}
			}
		}
		.image-box {
			img {
				border-radius: 5px;
				width: 100%;
			}
		}
	}
	blockquote {
		.icon-box {
			color: #fceee7;
		}
		h5.name {
			&:before {
				position: absolute;
				content: '';
				background: #141417;
				width: 15px;
				height: 2px;
				left: 0px;
				top: 12px;
			}
		}
	}
	.list {
		li {
			&:before {
				position: absolute;
				content: '';
				left: 0px;
				top: 6px;
				width: 12px;
				height: 12px;
				border: 2px solid #141417;
				border-radius: 50%;
			}
		}
	}
	.post-share-option {
		.tags-list {
			li {
				a {
					padding: 9px 14px;
					border: 1px solid #e5e5e5;
					&:hover {
						color: #fff;
					}
				}
			}
		}
		border-top: 1px solid #e5e5e5;
		border-bottom: 1px solid #e5e5e5;
		.social-list {
			li {
				a {
					border: 1px solid #e6e6e6;
					color: #808080;
					&:hover {
						color: #fff;
					}
				}
			}
		}
	}
	.nav-btn {
		.single-btn {
			border: 1px solid #e7e7e7;
			&:hover {
				border-color: transparent;
				box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.07);
			}
			h6 {
				i {
					position: relative;
					font-size: 22px;
					top: 3px;
				}
			}
		}
		.single-btn.prev-btn {
			h6 {
				i {
					margin-right: 8px;
				}
			}
		}
		.single-btn.next-btn {
			h6 {
				i {
					margin-left: 8px;
				}
			}
		}
	}
	.author-box {
		box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.07);
		.author-thumb {
			img {
				width: 100%;
				border-radius: 50%;
			}
		}
	}
	.comments-form-area {
		.form-group {
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0px;
			}
			input[type='text'] {
				position: relative;
				display: inline-block;
				width: 100%;
				height: 50px;
				font-size: 16px;
				font-family: 'Poppins', sans-serif;
				color: #808080;
				border-bottom: 1px solid #e5e5e5;
				transition: all 500ms ease;
			}
			input[type='email'] {
				position: relative;
				display: inline-block;
				width: 100%;
				height: 50px;
				font-size: 16px;
				font-family: 'Poppins', sans-serif;
				color: #808080;
				border-bottom: 1px solid #e5e5e5;
				transition: all 500ms ease;
			}
			textarea {
				position: relative;
				display: inline-block;
				width: 100%;
				height: 50px;
				font-size: 16px;
				font-family: 'Poppins', sans-serif;
				color: #808080;
				border-bottom: 1px solid #e5e5e5;
				transition: all 500ms ease;
				height: 60px;
				resize: none;
			}
		}
		.check-box {
			label {
				color: #808080;
			}
		}
		.theme-btn {
			padding: 13px 31px;
			background: #e35712;
			color: #fff !important;
			border: none;
		}
	}
}
.page-title {
	.post-title {
		h2 {
			color: #fff;
		}
		.post-info {
			li {
				color: #fff;
				&:before {
					position: absolute;
					content: '';
					background: #e5e5e5;
					width: 6px;
					height: 6px;
					top: 12px;
					right: -19px;
					border-radius: 50%;
				}
				&:last-child {
					&:before {
						display: none;
					}
				}
			}
		}
	}
}
.page-title.blog-details {
	padding-top: 237px;
	padding-bottom: 182px;
}
.sidebar-page-container.blog-details-3 {
	padding-top: 420px;
	.bg-image {
		width: 100%;
		height: 722px;
		background-size: cover;
		border-radius: inherit;
		background-position: bottom center;
	}
}
