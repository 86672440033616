/*** use-font ***/
@import "https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
/*** 

====================================================================
  Reset
====================================================================

 ***/
/*** 

====================================================================
  Global Settings
====================================================================

 ***/
/** use-font-family **/
/* Preloader */
@keyframes spin-1 {
	from {
		transform: rotateY(0) rotateX(30deg);
	}
	to {
		transform: rotateY(1turn) rotateX(30deg);
	}
}
@keyframes spin-2{
	from {
		transform: rotateY(0);
	}
	to {
		transform: rotateY(1turn);
	}
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}
@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
@keyframes circle {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}
@-webkit-keyframes ripple {
	70% {
		-webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}
@keyframes ripple {
	70% {
		-webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}
@keyframes slide {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 1920px 0;
	}
}
@-webkit-keyframes slide {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 1920px 0;
	}
}

* {
	margin: 0px;
	padding: 0px;
	border: none;
	outline: none;
}
body {
	font-size: 16px;
	color: #fff;
	line-height: 28px;
	font-weight: 400;
	background: #00061A;
	font-family: 'Archivo', sans-serif;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	-webkit-font-smoothing: antialiased;
}
.auto-container {
	position: static;
	max-width: 1296px;
	padding: 0px 15px;
	margin: 0 auto;
}
.small-container {
	max-width: 680px;
	margin: 0 auto;
}
.boxed_wrapper {
	position: relative;
	margin: 0 auto;
	overflow: hidden !important;
	width: 100%;
	min-width: 300px;
}
a {
	color: #fff;
	text-decoration: none;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	&:hover {
		color: #F6EC4F;
		text-decoration: none;
		outline: none;
	}
}
input {
	font-family: 'Archivo', sans-serif;
	font-weight: 400;
	font-size: 16px;
	background: transparent;
	transition: all 500ms ease;
	&:focus {
		outline: none;
		box-shadow: none;
		transition: all 500ms ease;
	}
}
button {
	font-family: 'Archivo', sans-serif;
	font-weight: 400;
	font-size: 16px;
	background: transparent;
	&:focus {
		outline: none;
		box-shadow: none;
		transition: all 500ms ease;
	}
}
select {
	font-family: 'Archivo', sans-serif;
	font-weight: 400;
	font-size: 16px;
	background: transparent;
}
textarea {
	font-family: 'Archivo', sans-serif;
	font-weight: 400;
	font-size: 16px;
	background: transparent;
	&:focus {
		outline: none;
		box-shadow: none;
		transition: all 500ms ease;
	}
}
::-webkit-input-placeholder {
	color: inherit;
}
::-moz-input-placeholder {
	color: inherit;
}
::-ms-input-placeholder {
	color: inherit;
}
ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}
li {
	list-style: none;
	padding: 0px;
	margin: 0px;
}
p {
	position: relative;
	font-family: 'Archivo', sans-serif;
	color: #fff;
	font-size: 16px;
	line-height: 29px;
	margin-bottom: 0px;
	font-weight: 400;
	transition: all 500ms ease;
	&:last-child {
		margin-bottom: 0px;
	}
}
h1 {
	position: relative;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	color: #fff;
	line-height: 29px;
	margin: 0px;
	transition: all 500ms ease;
	font-size: 50px;
	line-height: 60px;
}
h2 {
	position: relative;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	color: #fff;
	line-height: 29px;
	margin: 0px;
	transition: all 500ms ease;
	font-size: 40px;
	font-weight: 700;
	line-height: 50px;
}
h3 {
	position: relative;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	color: #fff;
	line-height: 29px;
	margin: 0px;
	transition: all 500ms ease;
	font-size: 32px;
	font-weight: 600;
	line-height: 42px;
}
h4 {
	position: relative;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	color: #fff;
	line-height: 29px;
	margin: 0px;
	transition: all 500ms ease;
	font-size: 26px;
	font-weight: 500;
	line-height: 36px;
}
h5 {
	position: relative;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	color: #fff;
	line-height: 29px;
	margin: 0px;
	transition: all 500ms ease;
}
h6 {
	position: relative;
	font-family: 'Rubik', sans-serif;
	font-weight: 400;
	color: #fff;
	line-height: 29px;
	margin: 0px;
	transition: all 500ms ease;
}
.font_family_rubik {
	font-family: 'Rubik', sans-serif;
}
.font_family_archivo {
	font-family: 'Archivo', sans-serif;
}
.loader-wrap {
	z-index: 9999;
	width: 100%;
	height: 100%;
	background: linear-gradient(219deg, #FB0E76 0%, #910694 100%);
	position: fixed;
}
.preloader {
	display: flex;
	animation: spin-1 8s linear infinite;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
	width: 300px;
	height: 300px;
	color: #F6EC4F;
	transform-style: preserve-3d;
}
.preloader-ring {
	transform-style: preserve-3d;
	animation: spin-2 4s linear infinite;
	position: relative;
	height: 60px;
	width: 20px;
	&:nth-child(even) {
		animation-direction: reverse;
	}
}
.preloader-sector {
	font-size: 40px;
	line-height: 60px;
	font-weight: 600;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	text-transform: uppercase;
	transform: translateZ(160px);
	display: inline-block;
	width: 100%;
	height: 100%;
	&:empty {
		&:before {
			display: inline-block;
			width: 100%;
			height: 100%;
			background: linear-gradient(transparent 50%, #f6ec4ff5 50% 55%, transparent 55%);
			content: "";
		}
	}
}
.hendel__preloder {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.centred {
	text-align: center;
}
.pull-left {
	float: left;
}
.pull-right {
	float: right;
}
figure {
	margin: 0px;
}
img {
	display: inline-block;
	max-width: 100%;
	height: auto;
	transition-delay: .1s;
	transition-timing-function: ease-in-out;
	transition-duration: .7s;
	transition-property: all;
}
.theme-btn {
	position: relative;
	display: flex;
	overflow: hidden;
	vertical-align: middle;
	font-size: 12px;
	line-height: 24px;
	font-weight: 500;
	font-family: 'Rubik', sans-serif;
	color: #fff !important;
	text-align: center;
	padding: 15px 20px;
	border-radius: 5px;
	letter-spacing: 2.4px;
	text-transform: uppercase;
	z-index: 1;
	-webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
	-o-transition: all cubic-bezier(.4,0,.2,1) .4s;
	transition: all cubic-bezier(.4,0,.2,1) .4s;
	align-items: center;
	gap: 10px;
	i {
		position: relative;
		font-size: 15px;
	}
}
.theme-btn-one {
	&:before {
		position: absolute;
		content: '';
		width: 0%;
		height: 100%;
		left: 0px;
		top: 0px;
		z-index: -1;
		transition: all 500ms ease;
	}
	&:hover {
		&:before {
			width: 100%;
		}
	}
}
.theme-btn-two {
	background: #fff;
	padding: 12px 62px 12px 30px;
	&:hover {
		padding: 12px 69px 12px 30px;
		span {
			&:before {
				-webkit-transform: translateY(-50%) translateX(-30px);
				-ms-transform: translateY(-50%) translateX(-30px);
				transform: translateY(-50%) translateX(-30px);
				opacity: 0;
			}
			&:after {
				-webkit-transform: translateY(-50%) translateX(0);
				-ms-transform: translateY(-50%) translateX(0);
				transform: translateY(-50%) translateX(0);
				opacity: 1;
			}
		}
		&:before {
			-webkit-transform: translateY(-50%) scaleX(1);
			-ms-transform: translateY(-50%) scaleX(1);
			transform: translateY(-50%) scaleX(1);
			width: 25px;
		}
	}
	span {
		position: relative;
		color: transparent;
		&:before {
			content: attr(data-text);
			position: absolute;
			top: 50%;
			left: 0;
			-webkit-transform: translateY(-50%) translateX(0);
			-ms-transform: translateY(-50%) translateX(0);
			transform: translateY(-50%) translateX(0);
			width: 100%;
			-webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
			-o-transition: all cubic-bezier(.4,0,.2,1) .4s;
			transition: all cubic-bezier(.4,0,.2,1) .4s;
			color: #141417;
		}
		&:after {
			content: attr(data-text);
			position: absolute;
			top: 50%;
			left: 0;
			-webkit-transform: translateY(-50%) translateX(0);
			-ms-transform: translateY(-50%) translateX(0);
			transform: translateY(-50%) translateX(0);
			width: 100%;
			-webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
			-o-transition: all cubic-bezier(.4,0,.2,1) .4s;
			transition: all cubic-bezier(.4,0,.2,1) .4s;
			color: #f4244f;
			opacity: 0;
		}
	}
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		right: 32px;
		-webkit-transform: translateY(-50%) scaleX(1);
		-ms-transform: translateY(-50%) scaleX(1);
		transform: translateY(-50%) scaleX(1);
		width: 18px;
		height: 2px;
		background: #141417;
		transition: all 500ms ease;
		-webkit-transform-origin: right;
		-ms-transform-origin: right;
		transform-origin: right;
		transition: all 500ms ease;
		-moz-transition: all 500ms ease;
		-webkit-transition: all 500ms ease;
		-ms-transition: all 500ms ease;
		-o-transition: all 500ms ease;
	}
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 32px;
		width: 9px;
		height: 9px;
		border-right: 2px solid #141417;
		border-top: 2px solid #141417;
		-webkit-transform: translateY(-50%) rotate(45deg);
		-ms-transform: translateY(-50%) rotate(45deg);
		transform: translateY(-50%) rotate(45deg);
		transition: all 500ms ease;
		-moz-transition: all 500ms ease;
		-webkit-transition: all 500ms ease;
		-ms-transition: all 500ms ease;
		-o-transition: all 500ms ease;
	}
}
.theme-btn-three {
	padding: 12px 62px 12px 30px;
	&:hover {
		padding: 12px 69px 12px 30px;
		span {
			&:before {
				-webkit-transform: translateY(-50%) translateX(-30px);
				-ms-transform: translateY(-50%) translateX(-30px);
				transform: translateY(-50%) translateX(-30px);
				opacity: 0;
			}
			&:after {
				-webkit-transform: translateY(-50%) translateX(0);
				-ms-transform: translateY(-50%) translateX(0);
				transform: translateY(-50%) translateX(0);
				opacity: 1;
			}
		}
		&:before {
			-webkit-transform: translateY(-50%) scaleX(1);
			-ms-transform: translateY(-50%) scaleX(1);
			transform: translateY(-50%) scaleX(1);
			width: 25px;
		}
	}
	span {
		position: relative;
		color: transparent;
		&:before {
			content: attr(data-text);
			position: absolute;
			top: 50%;
			left: 0;
			-webkit-transform: translateY(-50%) translateX(0);
			-ms-transform: translateY(-50%) translateX(0);
			transform: translateY(-50%) translateX(0);
			width: 100%;
			transition: all 500ms ease;
			color: #fff;
		}
		&:after {
			content: attr(data-text);
			position: absolute;
			top: 50%;
			left: 0;
			-webkit-transform: translateY(-50%) translateX(0);
			-ms-transform: translateY(-50%) translateX(0);
			transform: translateY(-50%) translateX(0);
			width: 100%;
			transition: all 500ms ease;
			color: #141417;
			opacity: 0;
		}
	}
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		right: 32px;
		-webkit-transform: translateY(-50%) scaleX(1);
		-ms-transform: translateY(-50%) scaleX(1);
		transform: translateY(-50%) scaleX(1);
		width: 18px;
		height: 2px;
		background: #fff;
		transition: all 500ms ease;
		-webkit-transform-origin: right;
		-ms-transform-origin: right;
		transform-origin: right;
	}
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 32px;
		width: 9px;
		height: 9px;
		border-right: 2px solid #fff;
		border-top: 2px solid #fff;
		-webkit-transform: translateY(-50%) rotate(45deg);
		-ms-transform: translateY(-50%) rotate(45deg);
		transform: translateY(-50%) rotate(45deg);
		transition: all 500ms ease;
	}
}
.theme-btn-four {
	border-radius: 30px;
	background: #6a36ff;
	&:before {
		background-color: #141417;
		border-radius: 30px;
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		transform: translate(-100%, 0) rotate(10deg);
		transform-origin: top left;
		transition: .3s transform ease-out;
		will-change: transform;
		z-index: -1;
	}
	&:hover {
		&:before {
			transform: translate(0, 0);
		}
	}
}
.theme-btn-five {
	background: #612ade;
	padding: 17px 40px;
	&:before {
		position: absolute;
		content: '';
		background: #141417;
		width: 0%;
		height: 100%;
		left: 0px;
		top: 0px;
		z-index: -1;
		transition: all 500ms ease;
	}
	&:hover {
		&:before {
			width: 100%;
		}
	}
}
.theme-btn-six {
	border: 1px solid #e7e7e7;
	padding: 16px 40px;
	color: #141417 !important;
	&:hover {
		color: #fff !important;
		border-color: #612ade;
		&:before {
			width: 100%;
		}
	}
	&:before {
		position: absolute;
		content: '';
		background: #612ade;
		width: 0%;
		height: 100%;
		left: 0px;
		top: 0px;
		z-index: -1;
		transition: all 500ms ease;
	}
}
.theme-btn-seven {
	background: #f1592a;
	border-radius: 30px;
	font-size: 17px;
	font-weight: 500;
	font-family: 'Muli', sans-serif;
	padding: 17px 35px;
	letter-spacing: 1px;
	&:before {
		position: absolute;
		content: '';
		background: #141417;
		width: 0%;
		height: 100%;
		top: 0px;
		right: 0px;
		border-radius: 30px;
		z-index: -1;
		transition: all 500ms ease;
	}
	&:hover {
		&:before {
			width: 100%;
			left: 0px;
		}
	}
}
.theme-btn-eight {
	font-weight: 500;
	font-family: 'Spartan', sans-serif;
	border-radius: 0px;
	padding: 13px 26px 11px 26px;
	background: #ff7f4c;
	&:before {
		webkit-transition-duration: 800ms;
		transition-duration: 800ms;
		position: absolute;
		width: 200%;
		height: 200%;
		content: "";
		bottom: 110%;
		left: 50%;
		background-color: #141417;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
		border-radius: 50%;
		z-index: -1;
	}
	&:hover {
		&:before {
			bottom: -40%;
		}
	}
}
.theme-btn-nine {
	border-radius: 0px;
	font-family: 'Jost', sans-serif;
	padding: 17px 40px;
	font-size: 17px;
	background: #fff;
	color: #141417 !important;
	&:before {
		position: absolute;
		content: '';
		background: #141417;
		width: 100%;
		height: 0%;
		left: 0px;
		bottom: 0px;
		z-index: -1;
		transition: all 500ms ease;
	}
	&:hover {
		&:before {
			top: 0px;
			height: 100%;
		}
		color: #fff !important;
	}
}
.scroll-to-top {
	position: fixed;
    right: 15px;
    bottom: 20px;
    z-index: 99;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	svg {
		width: 20px;
		visibility: visible !important;
		opacity: 1 !important;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
		-o-transition: all cubic-bezier(.4,0,.2,1) .4s;
		transition: all cubic-bezier(.4,0,.2,1) .4s;
	}
	.scroll-bar {
		width: 50px;
		height: 2px;
		margin-right: 10px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			background-color: #f4244f;
			opacity: .3;
		}
		.bar-inner {
			position: absolute;
			top: 0;
			right: 0;
			height: 100%;
			background-color: currentColor;
		}
	}
	.scroll-bar-text {
		font-size: 14px;
		font-weight: 600;
		cursor: pointer;
		transition: all 500ms ease;
		&:hover {
			transform: scale(1.1);
		}
	}
}
.float-bob-y {
	animation-name: float-bob-y;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	-webkit-animation-name: float-bob-y;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	-moz-animation-name: float-bob-y;
	-moz-animation-duration: 2s;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;
	-ms-animation-name: float-bob-y;
	-ms-animation-duration: 2s;
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;
	-o-animation-name: float-bob-y;
	-o-animation-duration: 2s;
	-o-animation-iteration-count: infinite;
	-o-animation-timing-function: linear;
}
.float-bob-x {
	animation-name: float-bob-x;
	animation-duration: 15s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	-webkit-animation-name: float-bob-x;
	-webkit-animation-duration: 15s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	-moz-animation-name: float-bob-x;
	-moz-animation-duration: 15s;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;
	-ms-animation-name: float-bob-x;
	-ms-animation-duration: 15s;
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;
	-o-animation-name: float-bob-x;
	-o-animation-duration: 15s;
	-o-animation-iteration-count: infinite;
	-o-animation-timing-function: linear;
}
.main-header {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	z-index: 999;
	width: 100%;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	.outer-box {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}
}
.sticky-header {
	position: fixed;
	opacity: 0;
	visibility: hidden;
	left: 0px;
	top: 0px;
	width: 100%;
	z-index: 0;
	background-color: #00061A;
	box-shadow: 0px 10px 20px 0px rgba(27, 24, 47, 0.10);
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transform: translateY(-100%);
	border-bottom: 0.5px solid #f6ec4f30;
	.main-menu {
		.navigation {
			li.dropdown {
				.dropdown-btn {
					top: 33px;
				}
			}
			>li.current {
				border-bottom: none;
			}
			>li {
				a {
					&:before {
						display: none;
					}
				}
				>a {
					padding: 30px 0px;
				}
			}
		}
	}
	.logo-box {
		padding: 14px 0px;
	}
	.nav-right {
		margin-left: 30px;
	}
}
.fixed-header {
	.sticky-header {
		z-index: 999;
		opacity: 1;
		visibility: visible;
		-ms-animation-name: fadeInDown;
		-moz-animation-name: fadeInDown;
		-op-animation-name: fadeInDown;
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
		-ms-animation-duration: 500ms;
		-moz-animation-duration: 500ms;
		-op-animation-duration: 500ms;
		-webkit-animation-duration: 500ms;
		animation-duration: 500ms;
		-ms-animation-timing-function: linear;
		-moz-animation-timing-function: linear;
		-op-animation-timing-function: linear;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-ms-animation-iteration-count: 1;
		-moz-animation-iteration-count: 1;
		-op-animation-iteration-count: 1;
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		transform: translateY(0%);
	}
}
.main-menu {
	.navbar-collapse {
		padding: 0px;
		display: block !important;
	}
	.navigation {
		margin: 0px;
		>li {
			position: relative;
			float: left;
			z-index: 2;
			margin: 0px 32px;
			-webkit-transition: all 300ms ease;
			-moz-transition: all 300ms ease;
			-ms-transition: all 300ms ease;
			-o-transition: all 300ms ease;
			transition: all 300ms ease;
			&:last-child {
				margin-right: 0px !important;
			}
			&:first-child {
				margin-left: 0px !important;
			}
			>a {
				position: relative;
				display: block;
				text-align: center;
				font-size: 18px;
				line-height: normal;
				font-family: 'Rubik', sans-serif;
				padding: 50px 0px;
				font-weight: 500;
				opacity: 1;
				color: #fff;
				overflow: hidden;
				z-index: 1;
				-webkit-transition: all 500ms ease;
				-moz-transition: all 500ms ease;
				-ms-transition: all 500ms ease;
				-o-transition: all 500ms ease;
				transition: all 500ms ease;
			}
			>ul {
				position: absolute;
				left: inherit;
				top: 100%;
				width: 230px;
				margin-top: 15px;
				z-index: 100;
				display: none;
				opacity: 0;
				padding-top: 0px;
				padding-bottom: 0px;
				visibility: hidden;
				background: rgba(0, 6, 26, 1);
				border: 1px solid rgba(255, 255, 255, 0.15);
				box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
				transition: all 500ms ease;
				-moz-transition: all 500ms ease;
				-webkit-transition: all 500ms ease;
				-ms-transition: all 500ms ease;
				-o-transition: all 500ms ease;
				padding: 15px 24px;
				>li {
					&:hover {
						>a {
							color: #F6EC4F !important;
						}
					}
					position: relative;
					width: 100%;
					>a {
						position: relative;
						display: block;
						padding: 10px 0px;
						line-height: 24px;
						font-weight: 400;
						font-size: 16px;
						font-family: 'Rubik', sans-serif;
						text-transform: capitalize;
						color: #fff;
						text-align: left;
						transition: all 500ms ease;
						-moz-transition: all 500ms ease;
						-webkit-transition: all 500ms ease;
						-ms-transition: all 500ms ease;
						-o-transition: all 500ms ease;
						color: #fff !important;
						border-bottom: 1px solid rgba(255, 255, 255, 0.15);
					}
					&:last-child {
						>a {
							border-bottom: none;
						}
					}
					>ul {
						position: absolute;
						right: 100%;
						top: 0%;
						margin-top: 15px;
						width: 230px;
						z-index: 100;
						display: none;
						background: rgba(0, 6, 26, 1);
						border: 1px solid rgba(255, 255, 255, 0.15);
						box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
						transition: all 500ms ease;
						-moz-transition: all 500ms ease;
						-webkit-transition: all 500ms ease;
						-ms-transition: all 500ms ease;
						-o-transition: all 500ms ease;
						>li {
							position: relative;
							width: 100%;
							&:last-child {
								border-bottom: none;
								>a {
									border-bottom: none;
								}
							}
							>a {
								position: relative;
								display: block;
								padding: 8px 0px;
								line-height: 24px;
								font-weight: 500;
								border-bottom: 1px solid rgba(255, 255, 255, 0.15);
								font-size: 16px;
								font-family: 'Rubik', sans-serif;
								text-transform: capitalize;
								color: #fff !important;
								text-align: left;
								transition: all 500ms ease;
								-moz-transition: all 500ms ease;
								-webkit-transition: all 500ms ease;
								-ms-transition: all 500ms ease;
								-o-transition: all 500ms ease;
							}
						}
						>li.dropdown {
							>a {
								&:after {
									font-family: 'Font Awesome 5 Pro';
									content: "\f105";
									position: absolute;
									right: 20px;
									top: 12px;
									display: block;
									line-height: 24px;
									font-size: 16px;
									font-weight: 900;
									z-index: 5;
								}
							}
						}
					}
					>ul.from-right {
						left: auto;
						right: 0px;
					}
				}
				>li.dropdown {
					>a {
						&:after {
							font-family: 'icomoon' !important;
							content: "\e906";
							position: absolute;
							right: 20px;
							top: 8px;
							display: block;
							line-height: 24px;
							font-size: 16px;
							font-weight: 800;
							text-align: center;
							z-index: 5;
						}
					}
				}
			}
			>.megamenu {
				position: absolute;
				left: inherit;
				top: 100%;
				width: 230px;
				margin-top: 15px;
				z-index: 100;
				display: none;
				opacity: 0;
				padding-top: 0px;
				padding-bottom: 0px;
				visibility: hidden;
				background: rgba(0, 6, 26, 1);
				border: 1px solid rgba(255, 255, 255, 0.15);
				box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
				transition: all 500ms ease;
				-moz-transition: all 500ms ease;
				-webkit-transition: all 500ms ease;
				-ms-transition: all 500ms ease;
				-o-transition: all 500ms ease;
				li {
					>a {
						position: relative;
						display: block;
						padding: 10px 0px;
						line-height: 24px;
						font-weight: 400;
						font-size: 16px;
						font-family: 'Rubik', sans-serif;
						text-transform: capitalize;
						color: #fff;
						text-align: left;
						transition: all 500ms ease;
						-moz-transition: all 500ms ease;
						-webkit-transition: all 500ms ease;
						-ms-transition: all 500ms ease;
						-o-transition: all 500ms ease;
						padding: 6px 0px;
						display: inline-block;
						&:before {
							position: absolute;
							content: '';
							width: 0%;
							height: 1px;
							left: 0px;
							bottom: 8px;
							transition: all 500ms ease;
						}
						&:hover {
							&:before {
								width: calc(100% - 15px);
								left: 15px;
							}
							padding-left: 15px;
						}
					}
					&:last-child {
						>a {
							border-bottom: none;
						}
					}
				}
			}
			&:hover {
				a {
					color: #F6EC4F;
				}
				.dropdown-btn {
					color: #F6EC4F !important;
				}
				&:before {
					width: 100%;
				}
			}
			a.active {
				color: #F6EC4F;
			}
			>ul.from-right {
				left: auto;
				right: 0px;
			}
			&:before {
				content: "";
				background: #800599;
				width: 0%;
				height: 3px;
				position: absolute;
				left: 0;
				bottom: -3px;
				transition: all 500ms ease;
			}
			.active {
				border-bottom: 3px solid #800599;
			}
		}
		>li.current {
			.dropdown-btn {
				color: #F6EC4F !important;
			}
		}
		>li.dropdown {
			&:hover {
				>ul {
					visibility: visible;
					opacity: 1;
					margin-top: 6px;
					top: 100%;
				}
				>.megamenu {
					visibility: visible;
					opacity: 1;
					margin-top: 6px;
					top: 100%;
				}
			}
			>.megamenu {
				position: absolute;
				width: 100%;
				padding: 30px 50px;
				left: 0px;
			}
		}
		li {
			>ul {
				>li.dropdown {
					&:hover {
						>ul {
							visibility: visible;
							opacity: 1;
							top: 0%;
							margin-top: 0px;
							margin-right: 34px;
							padding: 15px 10px;
						}
					}
				}
			}
		}
		li.dropdown {
			.dropdown-btn {
				position: absolute;
				right: -23px;
				top: 53px;
				width: 15px;
				height: 15px;
				text-align: center;
				font-size: 14px;
				line-height: 26px;
				color: #fff;
				cursor: pointer;
				z-index: 5;
				transition: all 500ms ease;
			}
			ul {
				li.dropdown {
					.dropdown-btn {
						display: none;
					}
				}
			}
			.megamenu {
				li {
					h6 {
						position: relative;
						display: block;
						border-bottom: 1px solid #e5e5e5;
						padding-bottom: 13px;
						padding-top: 13px;
						font-size: 16px;
						line-height: 26px;
						font-weight: 700;
						text-transform: uppercase;
						color: #141417;
						margin-bottom: 7px;
					}
				}
			}
		}
	}
}
.header__style__one {
	.outer-box {
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	}
	.sticky-header {
		.outer-box {
			border-bottom: none;
		}
	}
}
.menu-area {
	.mobile-nav-toggler {
		position: relative;
		float: right;
		font-size: 40px;
		line-height: 50px;
		cursor: pointer;
		color: #3786ff;
		display: none;
		.icon-bar {
			position: relative;
			height: 2px;
			width: 30px;
			display: block;
			margin-bottom: 5px;
			background-color: #F6EC4F;
			-webkit-transition: all 300ms ease;
			-moz-transition: all 300ms ease;
			-ms-transition: all 300ms ease;
			-o-transition: all 300ms ease;
			transition: all 300ms ease;
			&:last-child {
				margin-bottom: 0px;
			}
		}
	}
}
.header-lower {
	.theme-btn {
		padding: 15px 30px;
	}
	.outer-box {
		.logo-box {
			padding: 30px 0px;
			.logo {
				min-width: 200px;
				img {
					width: 100%;
				}
			}
		}
	}
}
.nav-outer {
	.mobile-nav-toggler {
		position: relative;
		float: right;
		font-size: 40px;
		line-height: 50px;
		cursor: pointer;
		color: #3786ff;
		display: none;
	}
}
.mobile-menu {
	position: fixed;
	right: 0;
	top: 0;
	width: 300px;
	padding-right: 30px;
	max-width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	z-index: 999999;
	transition: all 900ms ease;
	.navbar-collapse {
		display: block !important;
	}
	.nav-logo {
		position: relative;
		padding: 50px 25px;
		text-align: left;
		padding-bottom: 50px;
	}
	.menu-backdrop {
		position: fixed;
		left: 0%;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		transition: all 900ms ease;
		background-color: #000;
	}
	.menu-box {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		max-height: 100%;
		overflow-y: auto;
		background: #00061A;
		padding: 0px 0px;
		z-index: 5;
		opacity: 0;
		visibility: hidden;
		border-radius: 0px;
		-webkit-transform: translateX(100%);
		-ms-transform: translateX(100%);
		transform: translateX(100%);
		transition: all 900ms ease !important;
	}
	.close-btn {
		position: absolute;
		right: 25px;
		top: 10px;
		line-height: 30px;
		width: 24px;
		text-align: center;
		font-size: 16px;
		color: #ffffff;
		cursor: pointer;
		z-index: 10;
		-webkit-transition: all 0.9s ease;
		-moz-transition: all 0.9s ease;
		-ms-transition: all 0.9s ease;
		-o-transition: all 0.9s ease;
		transition: all 0.9s ease;
		&:hover {
			-webkit-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
		}
	}
	.navigation {
		position: relative;
		display: block;
		width: 100%;
		float: none;
		li {
			position: relative;
			display: block;
			border-top: 1px solid rgba(255,255,255,0.10);
			>ul {
				>li {
					&:first-child {
						border-top: 1px solid rgba(255,255,255,0.10);
					}
					>ul {
						display: none;
					}
				}
				display: none;
			}
			>a {
				position: relative;
				display: block;
				line-height: 24px;
				padding: 10px 25px;
				font-size: 15px;
				font-weight: 500;
				color: #ffffff;
				text-transform: uppercase;
				-webkit-transition: all 500ms ease;
				-moz-transition: all 500ms ease;
				-ms-transition: all 500ms ease;
				-o-transition: all 500ms ease;
				transition: all 500ms ease;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 0;
					border-left: 5px solid #fff;
					-webkit-transition: all 500ms ease;
					-moz-transition: all 500ms ease;
					-ms-transition: all 500ms ease;
					-o-transition: all 500ms ease;
					transition: all 500ms ease;
				}
			}
			ul {
				li {
					>a {
						font-size: 16px;
						margin-left: 20px;
						text-transform: capitalize;
					}
				}
			}
		}
		&:last-child {
			border-bottom: 1px solid rgba(255,255,255,0.10);
		}
		li.current {
			>a {
				&:before {
					height: 100%;
				}
			}
		}
		li.dropdown {
			.dropdown-btn {
				position: absolute;
				right: 6px;
				top: 6px;
				width: 32px;
				height: 32px;
				text-align: center;
				font-size: 16px;
				line-height: 32px;
				color: #ffffff;
				background: rgba(255,255,255,0.10);
				cursor: pointer;
				border-radius: 2px;
				-webkit-transition: all 500ms ease;
				-moz-transition: all 500ms ease;
				-ms-transition: all 500ms ease;
				-o-transition: all 500ms ease;
				transition: all 500ms ease;
				z-index: 5;
			}
			.dropdown-btn.open {
				color: #ffffff;
				-webkit-transform: rotate(90deg);
				-ms-transform: rotate(90deg);
				transform: rotate(90deg);
			}
		}
		>li.dropdown {
			>.megamenu {
				display: none;
			}
		}
	}
	.social-links {
		position: relative;
		padding: 0px 25px;
		li {
			position: relative;
			display: inline-block;
			margin: 0px 10px 10px;
			a {
				position: relative;
				line-height: 32px;
				font-size: 16px;
				color: #ffffff;
				-webkit-transition: all 500ms ease;
				-moz-transition: all 500ms ease;
				-ms-transition: all 500ms ease;
				-o-transition: all 500ms ease;
				transition: all 500ms ease;
			}
		}
	}
	.contact-info {
		position: relative;
		padding: 120px 30px 20px 30px;
		h4 {
			position: relative;
			font-size: 20px;
			color: #ffffff;
			font-weight: 700;
			margin-bottom: 20px;
		}
		ul {
			li {
				position: relative;
				display: block;
				font-size: 15px;
				color: rgba(255,255,255,0.80);
				margin-bottom: 3px;
				a {
					color: rgba(255,255,255,0.80);
					&:hover {
						text-decoration: underline;
					}
				}
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}
}
.mobile-menu-visible {
	overflow: hidden;
	.mobile-menu {
		opacity: 1;
		visibility: visible;
		.menu-backdrop {
			opacity: 0.70;
			visibility: visible;
			right: 100%;
			-webkit-transition: all .8s ease-out 0s;
			-o-transition: all .8s ease-out 0s;
		}
		.menu-box {
			opacity: 1;
			visibility: visible;
			-webkit-transition: all 0.7s ease;
			-o-transition: all 0.7s ease;
			transition: all 0.7s ease;
			-webkit-transform: translateX(0%);
			-ms-transform: translateX(0%);
			transform: translateX(0%);
		}
		.close-btn {
			-webkit-transform: rotate(360deg);
			-ms-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
}
div#mCSB_1_container {
	top: 0px !important;
}
.header-top-one {
	.top__inner {
		border-radius: 0px 0px 25px 25px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.10);
		background: rgba(255, 255, 255, 0.10);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;
	}
}
.top__hrader__left {
	ul {
		display: flex;
		align-items: center;
		gap: 22px;
	}
	li {
		span {
			color: #F6EC4F;
		}
		font-size: 14px;
		a {
			color: #fff;
		}
	}
}
.top__hrader__right {
	ul {
		display: flex;
		align-items: center;
		gap: 10px;
		li {
			a {
				border: 1px solid #272D40;
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				font-size: 12px;
				position: relative;
				z-index: 1;
				color: #fff;
				&:before {
					position: absolute;
					content: "";
					width: 100%;
					height: 100%;
					background-color: #800599;
					border: 1px solid #800599;
					transform: scale(0.0);
					transition: all 500ms ease;
					-moz-transition: all 500ms ease;
					-webkit-transition: all 500ms ease;
					-ms-transition: all 500ms ease;
					-o-transition: all 500ms ease;
					border-radius: 50%;
					z-index: -1;
				}
				&:hover {
					&:before {
						transform: scale(1.1);
					}
				}
			}
		}
	}
}
.mouse-pointer {
	position: fixed;
	top: 50%;
	left: -100px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 10px;
	height: 10px;
	pointer-events: none;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 9999;
	-webkit-transition-property: width, height, background;
	-o-transition-property: width, height, background;
	transition-property: width, height, background;
	-webkit-transition-duration: .9s;
	-o-transition-duration: .9s;
	transition-duration: .9s;
	-webkit-transition-timing-function: cubic-bezier(.19, .94, .336, 1);
	-o-transition-timing-function: cubic-bezier(.19, .94, .336, 1);
	transition-timing-function: cubic-bezier(.19, .94, .336, 1);
	border-radius: 50%;
	background: linear-gradient(224deg, #800599 0%, #FF0E75 100%);
	overflow: hidden;
	.icon {
		color: #fff;
		font-size: 14px;
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-100%, -50%);
		-ms-transform: translate(-100%, -50%);
		transform: translate(-100%, -50%);
		opacity: 0;
		-webkit-transition: .4s cubic-bezier(.225, 1, .316, .99);
		-o-transition: .4s cubic-bezier(.225, 1, .316, .99);
		transition: .4s cubic-bezier(.225, 1, .316, .99);
		i {
			margin: 0px 3px;
		}
	}
}
.mouse-pointer.display_none {
	display: none;
}
body.out {
	.mouse-pointer {
		width: 0;
		height: 0;
	}
}
.mouse-pointer.large {
	width: 65px;
	height: 65px;
	background: rgba(224,36,79,.0) !important;
	-webkit-box-shadow: 0 0 30px rgba(224,36,79, 0.4);
	box-shadow: 0 0 30px rgba(224,36,79, 0.4);
	display: block !important;
}
.mouse-pointer.style-two {
	background: #41a0ea;
}
.mouse-pointer.style-two.large {
	-webkit-box-shadow: 0 0 30px rgba(65,160,234, 0.4);
	box-shadow: 0 0 30px rgba(65,160,234, 0.4);
}
.mouse-pointer.style-three {
	background: #6a36ff;
}
.mouse-pointer.style-three.large {
	-webkit-box-shadow: 0 0 30px rgba(106,54,255, 0.4);
	box-shadow: 0 0 30px rgba(106,54,255, 0.4);
}
.mouse-pointer.style-four {
	background: #bc8664;
}
.mouse-pointer.style-four.large {
	-webkit-box-shadow: 0 0 30px rgba(188,134,100, 0.4);
	box-shadow: 0 0 30px rgba(188,134,100, 0.4);
}
.mouse-pointer.style-five {
	background: #f1592a;
}
.mouse-pointer.style-five.large {
	-webkit-box-shadow: 0 0 30px rgba(241,89,42, 0.4);
	box-shadow: 0 0 30px rgba(241,89,42, 0.4);
}
.mouse-pointer.style-six {
	background: #e93421;
}
.mouse-pointer.style-six.large {
	-webkit-box-shadow: 0 0 30px rgba(233,52,33, 0.4);
	box-shadow: 0 0 30px rgba(233,52,33, 0.4);
}
.mouse-pointer.style-seven {
	background: #f38836;
}
.mouse-pointer.style-seven.large {
	-webkit-box-shadow: 0 0 30px rgba(243,136,54, 0.4);
	box-shadow: 0 0 30px rgba(243,136,54, 0.4);
}
.mouse-pointer.style-eight {
	background: #ff7f4c;
}
.mouse-pointer.style-eight.large {
	-webkit-box-shadow: 0 0 30px rgba(255,127,76, 0.4);
	box-shadow: 0 0 30px rgba(255,127,76, 0.4);
}
.mouse-pointer.style-nine {
	background: #ae8c64;
}
.mouse-pointer.style-nine.large {
	-webkit-box-shadow: 0 0 30px rgba(174,140,100, 0.4);
	box-shadow: 0 0 30px rgba(174,140,100, 0.4);
}
.mouse-pointer.style-ten {
	background: #ff5e14;
}
.mouse-pointer.style-ten.large {
	-webkit-box-shadow: 0 0 30px rgba(255,94,20, 0.4);
	box-shadow: 0 0 30px rgba(255,94,20, 0.4);
}
.mouse-pointer.style-11 {
	background: #fb4754;
}
.mouse-pointer.style-11.large {
	-webkit-box-shadow: 0 0 30px rgba(251,71,84, 0.4);
	box-shadow: 0 0 30px rgba(251,71,84, 0.4);
}
.mouse-pointer.style-12 {
	background: #00d0f5;
}
.mouse-pointer.style-12.large {
	-webkit-box-shadow: 0 0 30px rgba(0,208,245, 0.4);
	box-shadow: 0 0 30px rgba(0,208,245, 0.4);
}
.mouse-pointer.style-13 {
	background: #a44ac5;
}
.mouse-pointer.style-13.large {
	-webkit-box-shadow: 0 0 30px rgba(164,74,197, 0.4);
	box-shadow: 0 0 30px rgba(164,74,197, 0.4);
}
.mouse-pointer.style-14 {
	background: #17be74;
}
.mouse-pointer.style-14.large {
	-webkit-box-shadow: 0 0 30px rgba(23,190,116, 0.4);
	box-shadow: 0 0 30px rgba(23,190,116, 0.4);
}
.mouse-pointer.small {
	width: 25px;
	height: 25px;
	background: rgba(227,87,18,0);
	-webkit-box-shadow: 0 0 30px #e35712;
	box-shadow: 0 0 30px #e35712;
}
.mouse-pointer.right {
	width: 50px;
	height: 50px;
	background: #000 !important;
	.icon {
		opacity: 1;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transition-delay: .2s;
		-o-transition-delay: .2s;
		transition-delay: .2s;
	}
}
.mouse-pointer.right.large {
	.icon {
		opacity: 0;
	}
	background: transparent !important;
}
.mouse-pointer.zoom {
	width: 80px;
	height: 80px;
	background: rgba(227,87,18,0);
	border: 2px solid #e35712;
}
.mouse-pointer.open {
	width: 80px;
	height: 80px;
	background: rgba(227,87,18,0);
	border: 2px solid #e35712;
}
.mouse-pointer.black {
	background: #000;
}
.mouse-pointer.black.large {
	background: rgba(0,0,0,.4);
	-webkit-box-shadow: 0 0 30px rgba(0,0,0,0.2);
	box-shadow: 0 0 30px rgba(0,0,0,0.2);
}
.mouse-pointer.black.small {
	background: rgba(0,0,0,.5);
	-webkit-box-shadow: 0 0 30px rgba(0,0,0,0.2);
	box-shadow: 0 0 30px rgba(0,0,0,0.2);
}
.mouse-pointer.black.right {
	background: rgba(0,0,0,0);
	border: 2px solid #000;
}
.mouse-pointer.black.zoom {
	background: rgba(0,0,0,0);
	border: 2px solid #000;
}
.mouse-pointer.black.open {
	background: rgba(0,0,0,0);
	border: 2px solid #000;
}
.touch {
	.mouse-pointer {
		display: none;
	}
}
.mouse-pointer.transparent {
	display: none;
	transition: all 500ms ease;
}
.main__footer {
	.top-inner {
		background: linear-gradient(238deg, rgba(246, 236, 79, 0.58) 0%, rgba(0, 6, 26, 0.00) 100%);
		padding: 23px 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.inner__box {
		overflow: hidden;
		transition: all 500ms ease;
		&:hover {
			.image__box {
				a {
					&:before {
						opacity: 0.2;
						transform: scale(1.1);
					}
				}
			}
		}
	}
	.image__box {
		overflow: hidden;
		position: relative;
		img {
			width: 100%;
			transition: all 500ms ease;
		}
		a {
			&:before {
				content: "";
				background: -webkit-linear-gradient(0deg, #c335da, #fc2643 100%);
				position: absolute;
				width: 100%;
				height: 100%;
				transform: scale(0.0);
				transition: all 500ms ease;
			}
		}
	}
}
.left__top {
	h3 {
		font-weight: 400;
	}
}
.footer__one {
	background: #00061A;
	padding: 150px 0px 30px;
	position: relative;
	overflow: hidden;
}
.footer-widget {
	.text {
		p {
			color: #fff;
		}
	}
	.widget-title {
		padding-bottom: 40px;
		position: relative;
		&:after {
			content: "";
			background: #F6EC4F;
			width: 37px;
			height: 2px;
			position: absolute;
			left: 0;
			top: 37px;
		}
	}
	.btn-box {
		display: flex;
	}
}
.footer-logo-widget {
	.text {
		p {
			padding: 30px 0px 20px;
		}
	}
}
.news-letter-widget {
	.form-group {
		input {
			border-radius: 5px;
			border: 1px solid rgba(255, 255, 255, 0.15);
			color: rgba(255, 255, 255, 0.25);
			font-family: Rubik;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			letter-spacing: 2.4px;
			text-transform: uppercase;
			width: 100%;
			padding: 22px;
		}
		padding-top: 22px;
		position: relative;
		.btn-box {
			position: absolute;
			right: 8px;
			top: 30px;
		}
	}
}
.widget_content {
	ul.instagram_list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;
	}
}
.footer-bottom {
	.copyright {
		p {
			text-align: center;
			color: #fff;
			a {
				color: #F6EC4F;
			}
		}
	}
	padding-top: 30px;
}
.footer-top {
	position: relative;
	margin-bottom: -60px;
	z-index: 9;
}
.see__pad {
	padding: 140px 0px;
}
.p_relative {
	position: relative !important;
}
.p_absolute {
	position: absolute !important;
}
.mt-5 {
	margin-top: 5px !important;
}
.mt-10 {
	margin-top: 10px !important;
}
.mt-15 {
	margin-top: 15px !important;
}
.mt-20 {
	margin-top: 20px !important;
}
.mt-25 {
	margin-top: 25px !important;
}
.mt-30 {
	margin-top: 30px !important;
}
.mt-35 {
	margin-top: 35px !important;
}
.mt-40 {
	margin-top: 40px !important;
}
.mt-45 {
	margin-top: 45px !important;
}
.mt-50 {
	margin-top: 50px !important;
}
.mt-55 {
	margin-top: 55px !important;
}
.mt-60 {
	margin-top: 60px !important;
}
.mt-65 {
	margin-top: 65px !important;
}
.mt-70 {
	margin-top: 70px !important;
}
.mt-75 {
	margin-top: 75px !important;
}
.mt-80 {
	margin-top: 80px !important;
}
.mb-5 {
	margin-bottom: 5px !important;
	margin-bottom: 5px !important;
}
.mb-10 {
	margin-bottom: 10px !important;
}
.mb-15 {
	margin-bottom: 15px !important;
}
.mb-20 {
	margin-bottom: 20px !important;
}
.mb-25 {
	margin-bottom: 25px !important;
}
.mb-30 {
	margin-bottom: 30px !important;
}
.mb-35 {
	margin-bottom: 35px !important;
}
.mb-40 {
	margin-bottom: 40px !important;
}
.mb-45 {
	margin-bottom: 45px !important;
}
.mb-50 {
	margin-bottom: 50px !important;
}
.mb-55 {
	margin-bottom: 55px !important;
}
.mb-60 {
	margin-bottom: 60px !important;
}
.mb-65 {
	margin-bottom: 65px !important;
}
.mb-70 {
	margin-bottom: 70px !important;
}
.mb-75 {
	margin-bottom: 75px !important;
}
.mb-80 {
	margin-bottom: 80px !important;
}
.ml-0 {
	margin-left: 0px !important;
	margin-left: 0px !important;
}
.mr-0 {
	margin-right: 0px !important;
	margin-right: 0px !important;
}
.mb-0 {
	margin-bottom: 0px !important;
}
.ml-5 {
	margin-left: 5px !important;
}
.ml-10 {
	margin-left: 10px !important;
}
.ml-15 {
	margin-left: 15px !important;
}
.ml-20 {
	margin-left: 20px !important;
}
.ml-25 {
	margin-left: 25px !important;
}
.ml-30 {
	margin-left: 30px !important;
}
.ml-35 {
	margin-left: 35px !important;
}
.ml-40 {
	margin-left: 40px !important;
}
.ml-45 {
	margin-left: 45px !important;
}
.ml-50 {
	margin-left: 50px !important;
}
.ml-55 {
	margin-left: 55px !important;
}
.ml-60 {
	margin-left: 60px !important;
}
.ml-65 {
	margin-left: 65px !important;
}
.ml-70 {
	margin-left: 70px !important;
}
.ml-75 {
	margin-left: 75px !important;
}
.ml-80 {
	margin-left: 80px !important;
}
.mr-5 {
	margin-right: 5px !important;
}
.mr-10 {
	margin-right: 10px !important;
}
.mr-15 {
	margin-right: 15px !important;
}
.mr-20 {
	margin-right: 20px !important;
}
.mr-25 {
	margin-right: 25px !important;
}
.mr-30 {
	margin-right: 30px !important;
}
.mr-35 {
	margin-right: 35px !important;
}
.mr-40 {
	margin-right: 40px !important;
}
.mr-45 {
	margin-right: 45px !important;
}
.mr-50 {
	margin-right: 50px !important;
}
.mr-55 {
	margin-right: 55px !important;
}
.mr-60 {
	margin-right: 60px !important;
}
.mr-65 {
	margin-right: 65px !important;
}
.mr-70 {
	margin-right: 70px !important;
}
.mr-75 {
	margin-right: 75px !important;
}
.mr-80 {
	margin-right: 80px !important;
}
.pt-0 {
	padding-top: 0px !important;
}
.pl-0 {
	padding-left: 0px !important;
}
.pr-0 {
	padding-right: 0px !important;
}
.pb-5 {
	padding-bottom: 5px !important;
	padding-bottom: 5px !important;
}
.mt-0 {
	margin-top: 0px !important;
}
.pt-10 {
	padding-top: 10px !important;
}
.pt-15 {
	padding-top: 15px !important;
}
.pt-20 {
	padding-top: 20px !important;
}
.pt-25 {
	padding-top: 25px !important;
}
.pt-30 {
	padding-top: 30px !important;
}
.pt-35 {
	padding-top: 35px !important;
}
.pt-40 {
	padding-top: 40px !important;
}
.pt-45 {
	padding-top: 45px !important;
}
.pt-50 {
	padding-top: 50px !important;
}
.pt-55 {
	padding-top: 55px !important;
}
.pt-60 {
	padding-top: 60px !important;
}
.pt-65 {
	padding-top: 65px !important;
}
.pt-70 {
	padding-top: 70px !important;
}
.pt-75 {
	padding-top: 75px !important;
}
.pt-80 {
	padding-top: 80px !important;
}
.pt-100 {
	padding-top: 100px !important;
}
.pt-140 {
	padding-top: 140px;
}
.pb-10 {
	padding-bottom: 10px !important;
}
.pb-15 {
	padding-bottom: 15px !important;
}
.pb-20 {
	padding-bottom: 20px !important;
}
.pb-25 {
	padding-bottom: 25px !important;
}
.pb-24 {
	padding-bottom: 24px !important;
}
.pb-30 {
	padding-bottom: 30px !important;
}
.pb-35 {
	padding-bottom: 35px !important;
}
.pb-40 {
	padding-bottom: 40px !important;
}
.pb-45 {
	padding-bottom: 45px !important;
}
.pb-50 {
	padding-bottom: 50px !important;
}
.pb-55 {
	padding-bottom: 55px !important;
}
.pb-60 {
	padding-bottom: 60px !important;
}
.pb-65 {
	padding-bottom: 65px !important;
}
.pb-70 {
	padding-bottom: 70px !important;
}
.pb-75 {
	padding-bottom: 75px !important;
}
.pb-80 {
	padding-bottom: 80px !important;
}
.pb-100 {
	padding-bottom: 100px !important;
}
.pb-140 {
	padding-bottom: 140px;
}
.pl-5 {
	padding-left: 5px !important;
}
.pl-10 {
	padding-left: 10px !important;
}
.pl-15 {
	padding-left: 15px !important;
}
.pl-20 {
	padding-left: 20px !important;
}
.pl-25 {
	padding-left: 25px !important;
}
.pl-30 {
	padding-left: 30px !important;
}
.pl-35 {
	padding-left: 35px !important;
}
.pl-40 {
	padding-left: 40px !important;
}
.pl-45 {
	padding-left: 45px !important;
}
.pl-50 {
	padding-left: 50px !important;
}
.pl-55 {
	padding-left: 55px !important;
}
.pl-60 {
	padding-left: 60px !important;
}
.pl-65 {
	padding-left: 65px !important;
}
.pl-70 {
	padding-left: 70px !important;
}
.pl-75 {
	padding-left: 75px !important;
}
.pl-80 {
	padding-left: 80px !important;
}
.pr-5 {
	padding-right: 5px !important;
}
.pr-10 {
	padding-right: 10px !important;
}
.pr-15 {
	padding-right: 15px !important;
}
.pr-20 {
	padding-right: 20px !important;
}
.pr-25 {
	padding-right: 25px !important;
}
.pr-30 {
	padding-right: 30px !important;
}
.pr-35 {
	padding-right: 35px !important;
}
.pr-40 {
	padding-right: 40px !important;
}
.pr-45 {
	padding-right: 45px !important;
}
.pr-50 {
	padding-right: 50px !important;
}
.pr-55 {
	padding-right: 55px !important;
}
.pr-60 {
	padding-right: 60px !important;
}
.pr-65 {
	padding-right: 65px !important;
}
.pr-70 {
	padding-right: 70px !important;
}
.pr-75 {
	padding-right: 75px !important;
}
.pr-80 {
	padding-right: 80px !important;
}
.sub__title {
	h4 {
		color: #F6EC4F;
		font-family: Archivo;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 13.2px;
		text-transform: uppercase;
	}
}
.title__data {
	position: relative;
	// padding-left: 61px;
	padding-bottom: 60px;
	// &:before {
	// 	content: "";
	// 	background-image: url(../../images/resource/02.png);
	// 	width: 50px;
	// 	height: 70px;
	// 	position: absolute;
	// 	background-repeat: no-repeat;
	// 	left: 0;
	// 	top: 5px;
	// 	background-size: contain;
	// }
}
.title {
	padding-top: 10px;
}
.owl-nav {
	display: none;
}
.owl-dots {
	display: none;
}
@media (min-width:1296px) {
	.container {
		max-width: 1296px;
		padding: 0px 15px;
	}
}
